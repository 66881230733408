/**
 * NewsletterSubscription
 */

import React, { useState } from 'react';
import classNames from 'classnames';
import Button from 'Components/Button';
import BackgroundImage from 'Components/BackgroundImage';
import { httpPostWithCsrfToken } from 'utils/Http';
import { dataLayerPush } from 'utils/Datalayer';
import { asyncValidate } from 'utils/Validators';
import s from './NewsletterSubscription.module.scss';

const NewsletterSubscription = ({
    title = '',
    preamble = '',
    successText = '',
    endpoint = '',
    image = {},
    fiftyFiftyWidth = false,
    backgroundColor = '',
    backgroundImage = {},
    letUserSelectInterest = false,
    contactLists = [],
    defaultContactList = '',
    acceptanceText = '',
}) => {
    const [email, setEmail] = useState('');
    const [zip, setZip] = useState('');
    const [invalidEmail, setInvalidEmail] = useState(false);
    const [invalidZip, setInvalidZip] = useState(false);
    const [success, setSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [checkedContactLists, setCheckedContactLists] = useState([]);
    const [checkedAcceptance, setCheckedAcceptance] = useState(false);
    
    const post = () => {
        let url = '';
        if (letUserSelectInterest && checkedContactLists.length > 0) {
            const selectedInterests = checkedContactLists.join(',');
            url = `${`${endpoint}?mail=${email}&zip=${zip}&lists=${defaultContactList}`},${selectedInterests}`;
        } else {
            const contactListsToUse = checkedContactLists.length > 0 ? checkedContactLists : defaultContactList;
            url = `${endpoint}?mail=${email}&zip=${zip}&lists=${contactListsToUse}`;
        }

        httpPostWithCsrfToken(url, {}).then((result) => {
            setSuccess(result.success);
            setErrorMessage(!result.success ? result.errorMessage : '');

            if (result.success) {
                dataLayerPush({
                    'event': 'newsletter',
                });
            }
        });
    };

    const validateEmail = async () => {
        const isValid = email.includes('@') && email.includes('.');

        if(!isValid) {
            return false;
        }

        try {
            await asyncValidate({email});
            return true;
        } catch (e) {
            return false;
        }
    };

    const handleSubmitOnClick = () => {
        const isEmailValid = validateEmail();
        const isZipValid = !!zip; // TODO: Validate?

        if (isEmailValid && isZipValid) {
            post();
        } else {
            setInvalidEmail(!isEmailValid);
            setInvalidZip(!isZipValid);
        }
    };

    const handleListsOnChange = (e) => {
        let newState = [...checkedContactLists, e.target.id];
        if (checkedContactLists.includes(e.target.id)) {
            newState = newState.filter(id => id !== e.target.id);
        }

        setCheckedContactLists(newState);
    };

    const handleAcceptanceChange = (e) => {
        setCheckedAcceptance(!checkedAcceptance);
    }

    const classes = classNames(
        s['NewsletterSubscription'],
        {[s['NewsletterSubscription--FiftyFifty']]: fiftyFiftyWidth},
        {[s['NewsletterSubscription--Invert']]: !fiftyFiftyWidth && (backgroundImage && backgroundImage.src)},
        {[s[`NewsletterSubscription--${backgroundColor}`]]: backgroundColor},
    );

    const emailFieldClasses = classNames(
        s['NewsletterSubscription__Field'],
        {[s['NewsletterSubscription__Field--Invalid']]: invalidEmail},
    );

    const zipFieldClasses = classNames(
        s['NewsletterSubscription__Field'],
        {[s['NewsletterSubscription__Field--Invalid']]: invalidZip},
    );

    const backroundImageClasses = classNames(
        s['NewsletterSubscription__BackgroundImage'],
        {[s['NewsletterSubscription__BackgroundImage--Darken']]: !fiftyFiftyWidth},
    );

    const showContactListsCheckboxes = letUserSelectInterest && contactLists.length > 0;

    return (
        <section className={classes}>
            {backgroundImage && backgroundImage.src && (
                <BackgroundImage
                    className={backroundImageClasses}
                    image={backgroundImage}
                />
            )}
            <div className={s['NewsletterSubscription__Container']}>
                {image && image.src && (
                    <div
                        className={s['NewsletterSubscription__Image']}
                        style={{ backgroundImage: `url(${image.src})` }}
                    />
                )}
                <div className={s['NewsletterSubscription__Columns']}>
                    <div className={s['NewsletterSubscription__Column']}>
                        {!fiftyFiftyWidth && (
                            <div className={classNames(s['NewsletterSubscription__Icon'], 'fas', 'fa-envelope-open-text')} />
                        )}
                        <h2 className={s['NewsletterSubscription__Title']}>
                            {title}
                        </h2>
                        <p className={s['NewsletterSubscription__Preamble']}>
                            {preamble}
                        </p>
                        {!success ? (
                            <form className={s['NewsletterSubscription__Fields']} onSubmit={(e) => e.preventDefault()}>
                                <label htmlFor="newsletter-subscription-email" className="sr-only">E-postadress</label>
                                <input
                                    onKeyUp={(e) => setEmail(e.target.value)}
                                    className={emailFieldClasses}
                                    autoComplete="off"
                                    type="email"
                                    id="newsletter-subscription-email"
                                    placeholder={'E-postadress'}
                                />

                                <label htmlFor="newsletter-subscription-zip" className="sr-only">Postnummer</label>
                                <input
                                    onKeyUp={(e) => setZip(e.target.value)}
                                    className={zipFieldClasses}
                                    autoComplete="off"
                                    type="number"
                                    id="newsletter-subscription-zip"
                                    placeholder={'Postnummer'}
                                />

                                {showContactListsCheckboxes && (
                                    <div className={s['NewsletterSubscription__Checkboxes']}>
                                        {contactLists.filter(list => list.id !== defaultContactList)
                                            .map((list, index) => (
                                                <div
                                                    key={index}
                                                    className={s['NewsletterSubscription__CheckboxWrapper']}
                                                >
                                                    <label
                                                        htmlFor={list.id}
                                                        className={classNames(
                                                            s['NewsletterSubscription__CheckBoxLabel'],
                                                            {[s['NewsletterSubscription__CheckBoxLabel--Mandatory']]: list.id === defaultContactList },
                                                        )}
                                                    >
                                                        <input
                                                            onChange={handleListsOnChange}
                                                            type="checkbox"
                                                            id={list.id}
                                                            name={list.id}
                                                            className={classNames(
                                                                s['NewsletterSubscription__CheckBox'],
                                                                {[s['NewsletterSubscription__CheckBox--Checked']]: checkedContactLists.includes(list.id) || list.id === defaultContactList },
                                                            )}
                                                        />
                                                        <p className={s['NewsletterSubscription__CheckBoxText']}>
                                                            {list.name}
                                                        </p>
                                                    </label>
                                                </div>
                                            ))}
                                    </div>
                                )}

                                {acceptanceText && (
                                    <div className={s['NewsletterSubscription__AcceptanceWrapper']}>
                                        <input
                                            onChange={handleAcceptanceChange}
                                            type="checkbox"
                                            name="newsletter-subscription-acceptance"
                                            id="newsletter-subscription-acceptance"
                                            checked={checkedAcceptance}
                                            className={classNames(
                                                s['NewsletterSubscription__AcceptanceCheckBox'],
                                                {[s['NewsletterSubscription__AcceptanceCheckBox--Checked']]: checkedAcceptance },
                                            )}
                                        />
                                        <label
                                            htmlFor="newsletter-subscription-acceptance"
                                            className="sr-only"
                                        >Acceptera att vi behandlar dina personuppgifter enligt GDPR</label>
                                        <div
                                            className={s['NewsletterSubscription__AcceptanceText']}
                                            dangerouslySetInnerHTML={{ __html: acceptanceText }}
                                        />
                                    </div>
                                )}

                                <div className={s['NewsletterSubscription__SubmitWrapper']}>
                                    <Button
                                        disabled={!checkedAcceptance}
                                        type="submit"
                                        onClick={handleSubmitOnClick}
                                        text={'Prenumerera'}
                                    />
                                </div>
                            </form>
                        ) : (
                            <h3 className={s['NewsletterSubscription__Success']}>
                                {successText}
                            </h3>
                        )}
                        {errorMessage && (
                            <p
                                className={s['NewsletterSubscription__Error']}
                                dangerouslySetInnerHTML={{__html: errorMessage}}
                            />
                        )}
                    </div>

                    {fiftyFiftyWidth && (
                        <div className={classNames(s['NewsletterSubscription__Column'], s['NewsletterSubscription__Column--Icon'])}>
                            <div className={classNames(s['NewsletterSubscription__Icon'], 'fas', 'fa-envelope-open-text')} />
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default NewsletterSubscription;
